import * as React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { ContextProviderComponent } from "../Context";

const pageStyles = {
  background: "#ECECEC",
  minHeight: "100vh",
  margin: 0,
  padding: 0,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const Layout = ({ children, pageContext }) => {
  if (pageContext.layout === "heart") {
    return (
      <>
        <ContextProviderComponent>
          <div style={pageStyles}>{children}</div>
        </ContextProviderComponent>
      </>
    );
  }
  return (
    <>
      <ContextProviderComponent>
        <div style={pageStyles}>
          <Header />
          {children}
        </div>
        <Footer />
      </ContextProviderComponent>
    </>
  );
};

export default Layout;
