import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export default function Footer() {
  return (
    <footer
      style={{
        height: 100,
        width: "100%",
        backgroundColor: "#C3C3C3",
        margin: 0,
      }}
    >
      <StaticImage
        style={{
          mixBlendMode: "multiply",
          width: "20px",
          position: "relative",
          marginTop: 30,
          marginLeft: "50%",
          transform: "translateX(-50%)",
          opacity: 0.2,
        }}
        imgStyle={{ opacity: 0.2 }}
        src="../images/icon.png"
        alt="People taking part."
      />
      <div
        style={{
          marginTop: 10,
          position: "relative",
          display: "flex",
          justifyContent: "center",
          fontFamily: "Fira Mono",
          color: "#777",
          textDecoration: "none",
          fontSize: "0.6rem",
        }}
      >
        <a
          style={{ color: "#777", textDecoration: "underline" }}
          href="/lawyers.txt"
        >
          lawyers.txt{" "}
        </a>
        <> | </>
        <a
          style={{ color: "#777", textDecoration: "underline" }}
          href="/humans.txt"
        >
          {" "}
          humans.txt
        </a>
      </div>
    </footer>
  );
}
